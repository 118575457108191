import './styles/main.css'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersist from 'pinia-plugin-persist' // pinia数据持久化
import i18n from './i18n' // 导入多语言配置
import App from './App.vue'
// import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElIconModules from '@element-plus/icons-vue'
import { config } from '@/config'
import menu from '@/ui/components/Menu/v-menu'
import { hasPermission, tryGetI18nText } from '@/utils'
import { useUserStore } from '@/stores/user'
import router from './router'
import { ElNotification } from 'element-plus'
import { getWebIco } from '@/utils/env'
import * as Sentry from '@sentry/vue'

// 初始化浏览器tab上的ico
const favicon = document.createElement('link')
favicon.rel = 'icon'
favicon.href = getWebIco()
document.head.appendChild(favicon)

// 初始化Vue
const app = createApp(App)
const pinia = createPinia()
pinia.use(piniaPluginPersist)
app.use(pinia)
router.reloadRoutes(false)
app.use(i18n)
app.directive('menu', menu)
// app.use(ElementPlus)
// 注册所有 Element Plus 图标
for (const iconName in ElIconModules) {
  // console.log(iconName)
  config.elementPlusIconNames.push(iconName)
  app.component(iconName, ElIconModules[iconName])
}

// 注册指令: 元素是否可见, 需要开发者权限
app.directive('dev', {
  mounted(el) {
    if (!useUserStore().isDev) {
      el.parentNode && el.parentNode.removeChild(el)
    }
  },
})

// 注册指令: 元素是否可见。值针对页面唯一
app.directive('permission', {
  mounted(el, binding) {
    // 检查权限
    const permissionValue = binding.value
    if (!hasPermission(permissionValue)) {
      el.parentNode && el.parentNode.removeChild(el)
    }
  },
})

// 无特殊作用，用于指定权限名
// 比如： v-permission-title="'xx权限'"
// 支持国际化：v-permission-title="'xxx.xxx'"
app.directive('permission-title', {})

// 注册指令：是否可以触发鼠标事件。值针对页面唯一
app.directive('permission-event', {
  mounted(el, binding) {
    if (!hasPermission(binding.value)) {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          mutation.addedNodes.forEach((newNode) => {
            // console.log(newNode)
            if (newNode.nodeType === Node.ELEMENT_NODE) {
              lockElementEvent(newNode)
            }
          })
        })
      })

      observer.observe(el, { childList: true, subtree: true })
      lockElementEvent(el)
      // console.log('>>>>>>', el)
      el.querySelectorAll('*').forEach((child) => {
        // console.log(child)
        disableElement(child)
      })

      // 清理
      el._permissionEventObserver = observer
    }
  },
  unmounted(el) {
    // 清除事件监听器
    el.removeEventListener('click', preventClick, true)
    el.removeEventListener('contextmenu', preventClick, true)

    // 断开观察器
    if (el._permissionEventObserver) {
      el._permissionEventObserver.disconnect()
    }
  },
})

function lockElementEvent(el) {
  el.addEventListener('click', preventClick, true)
  el.addEventListener('contextmenu', preventClick, true)
  el.style.cursor = 'default' // 更改鼠标样式
}

// 注册指令：无权限时候置灰且无法触发点击事件。值针对页面唯一
app.directive('permission-enable', {
  beforeMount(el, binding) {
    if (!hasPermission(binding.value)) {
      disableElement(el)
      el.querySelectorAll('*').forEach((child) => {
        disableElement(child)
      })
    }
  },
  unmounted(el) {
    // 清除事件监听器
    el.removeEventListener('click', preventClick, true)
    el.removeEventListener('contextmenu', preventClick, true)
  },
})

function disableElement(element) {
  // 置灰样式
  element.style.opacity = '0.8'
  element.style.pointerEvents = 'none' // 禁止鼠标事件
  element.style.cursor = 'not-allowed' // 鼠标悬浮时的禁止图标
}

function preventClick(e) {
  e.stopPropagation()
  e.preventDefault()
}

app.use(router)
console.log('VITE_IS_RELEASE', import.meta.env.VITE_IS_RELEASE)
if (import.meta.env.VITE_IS_RELEASE === 'true') {
  Sentry.init({
    app,
    dsn: 'https://ea041fe10cb2cbcc33ec97f5b63a8db7@sentry.link-api.com/6',
    integrations: [
      // 只保留 replay 用于错误回放
      Sentry.replayIntegration(),
    ],
    // 性能追踪采样率设为 0%
    tracesSampleRate: 0,
    // 普通会话采样率设为 1%，避免收集过多正常用户数据
    replaysSessionSampleRate: 0.01,
    // 错误会话保持 100% 采样，以便完整收集错误信息
    replaysOnErrorSampleRate: 1.0,

    // 添加这个配置来禁用所有屏蔽
    replaysSessionOptions: {
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: true,
      maskTextSelector: 'none',
      maskInputSelector: 'none',
    },

    // 限制每个事件的大小
    maxValueLength: 500, // 限制值长度
    maxBreadcrumbs: 30, // 限制面包屑数量

    beforeSend(event) {
      // 过滤掉一些高频率但不重要的错误
      if (event?.exception?.values?.[0]) {
        const error = event.exception.values[0]

        // 转换为小写进行比较，避免大小写问题
        const errorType = error.type?.toLowerCase() || ''
        const errorValue = error.value?.toLowerCase() || ''

        if (
          // 网络错误
          errorType.includes('networkerror') ||
          errorValue.match(/net::/i) ||
          errorValue.includes('failed to fetch') ||
          errorValue.includes('network request failed') ||
          errorValue.includes('network error') ||
          // 超时相关
          errorType.includes('timeouterror') ||
          errorValue.includes('timeout') ||
          errorValue.includes('err_connection_timed_out') ||
          // 取消相关
          errorType.includes('cancelederror') ||
          errorType.includes('aborterror') ||
          errorValue.includes('cancel') ||
          errorValue.includes('abort') ||
          // Axios 特定错误
          (errorType.includes('axioserror') && (errorValue.includes('timeout') || errorValue.includes('cancel')))
        ) {
          return null
        }
      }

      // 清理过大的数据
      if (event.extra) {
        Object.keys(event.extra).forEach((key) => {
          const value = event.extra[key]
          if (typeof value === 'string' && value.length > 500) {
            event.extra[key] = value.substring(0, 500) + '...'
          }
        })
      }

      return event
    },
  })

  // 添加错误控制 - 使用闭包避免全局变量
  const createErrorHandler = () => {
    let errorCount = 0
    const errorTimeWindow = 60000 // 1分钟
    const errorLimit = 10 // 每分钟最多发送10个错误
    const errorMap = new Map() // 用于存储错误指纹

    const errorResetInterval = setInterval(() => {
      errorCount = 0
      errorMap.clear()
    }, errorTimeWindow)

    // 生成错误指纹
    const getErrorFingerprint = (error) => {
      if (!error) return ''
      return `${error.name}:${error.message}:${error.stack?.split('\n')[1] || ''}`
    }

    // 错误处理器
    const handleError = (error, context = {}) => {
      if (!error || errorCount >= errorLimit) {
        return
      }

      // 检查是否是重复错误
      const fingerprint = getErrorFingerprint(error)
      if (fingerprint) {
        const count = errorMap.get(fingerprint) || 0
        if (count >= 3) {
          // 同一错误最多报告3次
          return
        }
        errorMap.set(fingerprint, count + 1)
      }

      errorCount++

      // 添加额外上下文信息
      const errorInfo = {
        url: window.location.href,
        timestamp: new Date().toISOString(),
        userAgent: navigator.userAgent,
        ...context,
      }

      Sentry.withScope((scope) => {
        scope.setExtras(errorInfo)
        Sentry.captureException(error)
      })
    }

    return {
      handleError,
      cleanup: () => clearInterval(errorResetInterval),
    }
  }

  // 创建错误处理器实例
  const { handleError, cleanup: cleanupErrorHandler } = createErrorHandler()

  // 存储清理函数 - 添加重试机制
  const clearSentryStorage = (retryCount = 3) => {
    const executeClear = async (retriesLeft) => {
      try {
        const keys = Object.keys(localStorage)
        const sentryKeys = keys.filter((key) => key.startsWith('sentry'))

        // 批量删除，减少循环
        await Promise.all(
          sentryKeys.map(
            (key) =>
              new Promise((resolve) => {
                localStorage.removeItem(key)
                resolve()
              })
          )
        )
      } catch (e) {
        console.error('清理 Sentry 存储失败', e)
        if (retriesLeft > 0) {
          setTimeout(() => executeClear(retriesLeft - 1), 1000)
        }
      }
    }

    executeClear(retryCount)
  }

  // 添加全局错误监听
  app.config.errorHandler = (err, instance, info) => {
    handleError(err, {
      componentName: instance?.$options?.name || 'Unknown Component',
      errorInfo: info,
      vueVersion: app.version,
    })

    if (process.env.NODE_ENV !== 'production') {
      console.error(err)
    }
  }

  // 优化事件监听器
  window.addEventListener(
    'error',
    (event) => {
      if (event.error) {
        handleError(event.error, {
          eventType: 'error',
          fileName: event.filename,
          lineNumber: event.lineno,
          columnNumber: event.colno,
        })
      }
    },
    false
  )

  window.addEventListener(
    'unhandledrejection',
    (event) => {
      if (event.reason) {
        handleError(event.reason, {
          eventType: 'unhandledrejection',
          promiseState: 'rejected',
        })
      }
    },
    false
  )

  // 使用节流控制存储清理频率
  const throttledClearStorage = throttle(clearSentryStorage, 5000)

  // 周期性清理本地存储的 Sentry 数据
  const storageCleanInterval = setInterval(throttledClearStorage, 3600000)

  // 页面关闭时的清理
  window.addEventListener('beforeunload', () => {
    clearSentryStorage()
    cleanup()
  })

  // 全局清理函数
  const cleanup = () => {
    cleanupErrorHandler()
    clearInterval(storageCleanInterval)
  }

  // 节流函数实现
  function throttle(func, limit) {
    let inThrottle
    return function (...args) {
      if (!inThrottle) {
        func.apply(this, args)
        inThrottle = true
        setTimeout(() => (inThrottle = false), limit)
      }
    }
  }
}
app.mount('#app')
i18n.global.init()

/****************************************************************
 *              页面更新检测
 ****************************************************************/
console.log(import.meta.env.MODE)
console.log(navigator)
if (import.meta.env.PROD && 'serviceWorker' in navigator) {
  let displayUpdate = false
  // 注册 Service Worker
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/service-worker.js')
      .then((registration) => {
        console.log('[Service Worker] 注册成功，作用域是：', registration.scope)

        if (registration.installing) {
          console.log('[Service Worker] 正在安装.')
        } else if (registration.waiting) {
          console.log('[Service Worker] 已安装，等待激活')
        } else if (registration.active) {
          console.log('[Service Worker] 已经激活')
        }

        setInterval(() => {
          registration
            .update()
            .then(() => {
              console.log('[Service Worker] 检查更新成功.')
            })
            .catch((error) => {
              console.error('[Service Worker] 检查更新失败', error)
            })
        }, 10000) // 每10秒检查一次

        // 监听更新
        registration.onupdatefound = () => {
          const installingWorker = registration.installing
          if (installingWorker) {
            installingWorker.onstatechange = () => {
              console.log('[Service Worker] installingWorker.state = ' + installingWorker.state)

              if (installingWorker.state === 'installed') {
                if (navigator.serviceWorker.controller) {
                  if (displayUpdate) {
                    return
                  }
                  displayUpdate = true
                  ElNotification({
                    title: tryGetI18nText('app.updateTipTitle'),
                    message: tryGetI18nText('app.updateTipContent'),
                    type: 'warning',
                    duration: 0,
                    showClose: false,
                    onClick: () => {
                      // 先发送消息，等待 SW 处理完成后再刷新
                      installingWorker.postMessage({ type: 'SKIP_WAITING' })
                      // 监听 controllerchange 事件后再刷新
                      navigator.serviceWorker.addEventListener('controllerchange', () => {
                        window.location.reload(true)
                      })
                    },
                  })

                  console.log('[Service Worker] 新内容可用，请刷新页面')
                } else {
                  console.log('[Service Worker] 内容已缓存，可离线使用')
                }
              }
            }
          }
        }
      })
      .catch((error) => {
        console.error('[Service Worker] 注册失败, ', error)
      })
  })
} else {
  console.error('当前浏览器不支持 serviceWorker')
}

//
// const comps = import.meta.glob('/src/ui/**/*.*')
// console.error(comps)

/****************************************************************
 *              拦截用户按下 F5 键以刷新当前页面
 ****************************************************************/
// 添加全局的键盘事件监听器
// window.addEventListener('keydown', handleGlobalKeyDown)
//
// function handleGlobalKeyDown(event) {
//   // 拦截 F5 键
//   if (event.keyCode === 116) {
//     event.preventDefault() // 阻止默认行为
//     router.refreshCurPage()
//   }
// }
